<template>
    <main>
        <div class="row  m-0">
            <div class="col-xl-4 mt-4">
                <h1 class="ml-4">Dashboard</h1>
            </div>
        </div>

        <div class="row m-0 pt-5">
            <div class="col-lg-8 m-auto">
                <div class="card">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-xl-9">
                                <b class="card-heading">Les ventes <span
                                        id="revenu-title">: {{total}} DH</span></b>
                            </div>

                            <div class=" col-xl-3 float-right">

                                <select class="form-control" v-model="periode"
                                        @change="changeDate(dates[periode].from,dates[periode].to)">
                                    <option v-for="date in dates" v-bind:key="date.id" v-bind:value="date.id">
                                        {{date.label}}
                                    </option>
                                </select>
                            </div>

                        </div>
                        <div class="row " v-if="periode == 7">


                            <div class="col-xl-4 ">
                                <b-form-group label="De">
                                    <b-form-datepicker v-model="from"></b-form-datepicker>
                                </b-form-group>
                            </div>
                            <div class="col-xl-4 ">
                                <b-form-group label="à">
                                    <b-form-datepicker v-model="to"></b-form-datepicker>
                                </b-form-group>
                            </div>

                            <div class="col-xl-2 ml-4">
                                <div class="form-group  mb-0 " style="margin-top: 18%">
                                    <b-button class="w-100"  @click="getGraph(from,to)" variant="info" size="sm">
                                        Actualiser
                                    </b-button>
                                </div>
                            </div>



                        </div>
                    </div>
                    <div class="card-body">

                        <BarChart2 :height="400"  :data1="data1" :labels1="labels1"  v-if="loaded"/>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 m-auto">
                <div class="card">
                    <div class="card-body">

                        <pie-chart :height="300"  :magasins_ventes="magasins_ventes"  v-if="loaded"/>
                    </div>
                </div>
            </div>
        </div>

    </main>
</template>

<script>


    import BarChart2 from "@/components/BarChart2";
    import PieChart from "@/components/PieChart";

    export default {
        name: "Accueil",
        components: {PieChart, BarChart2},
        data() {
            return {
                dates: [
                    {
                        id: 0,
                        label: 'ce jour',
                        from: this.parser(new Date()),
                        to: this.parser(new Date()),
                    },
                    {
                        id: 1,
                        label: 'derniers 7 jours',
                        from: this.parser(new Date().setDate(new Date().getDate() - 7)),
                        to: this.parser(new Date()),
                    }, {
                        id: 2,
                        label: 'derniers 30 j',
                        from: this.parser(new Date().setDate(new Date().getDate() - 30)),
                        to: this.parser(new Date()),
                    }, {
                        id: 3,
                        label: 'ce mois',
                        from: this.parser(new Date(new Date().getFullYear(), new Date().getMonth(), 1)),
                        to: this.parser(new Date()),
                    }, {
                        id: 4,
                        label: 'mois precedent',
                        from: this.parser(new Date(new Date().getFullYear(), new Date().getMonth()-1, 1)),
                        to: this.parser(new Date(new Date().getFullYear(), new Date().getMonth(), 0)),
                    }, {
                        id: 5,
                        label: new Date().getFullYear(),
                        from: this.parser(new Date(new Date().getFullYear(), 0, 1)),
                        to: this.parser(new Date(new Date().getFullYear(), new Date().getMonth(), 1)),
                    }, {
                        id: 6,
                        label: new Date().getFullYear() - 1,
                        from: this.parser(new Date().setFullYear(new Date().getFullYear() - 1)),
                        to: this.parser(new Date()),
                    },
                    {
                        id: 7,
                        label: 'Entrez',
                    }
                ],
                periode: 1,
                // height:300,
                from: new Date(),
                to: new Date(),
                magasins_ventes:{},
                data1: [],
                labels1: [],
                loaded: false,
            }
        },

        computed: {
            // myStyles () {
            //     return {
            //         height: `${this.height}px`,
            //         position: 'relative'
            //     }
            // },
            total: function () {
                let sum_op = (max, cur) => max + cur;
                let sum = this.data1.map(el => parseFloat(el)).reduce(sum_op, 0);
                if (Number.isInteger(sum)) return sum
                else return sum.toFixed(2)
            },
        },
        methods: {
            changeDate(from, to) {
                if (this.periode != 7) {
                    this.getGraph(from, to)
                    this.loaded = false
                }
            },
            parser(d) {
                d = new Date(d);
                let day = d.getDate();
                let month = d.getMonth() + 1
                let year = d.getFullYear()
                return (year + '-' + month + '-' + day)
            },

            getGraph(from, to) {
                this.$http
                    .post('stats', {'from': from, 'to': to})
                    .then(response => {
                        this.labels1 = response.data.labels_date
                        this.data1 = response.data.stats_vente
                        this.magasins_ventes =  response.data.magasins_ventes
                        this.loaded = false
                        this.$nextTick(() => {
                            // Add the component back in
                            this.loaded = true;
                        });
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }

        },
        created() {
            this.getGraph(this.dates[1].from,this.dates[1].to)
            this.loaded = false

        },
    }
</script>

<style scoped>

    .card {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-clip: border-box;
        /*border: 1px solid #32394e;*/
        /*border-radius: 4px;*/

    }
    .card-body,.card-header {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        min-height: 1px;
        padding: 1.25rem;
    }
    .card-title {
        font-size: 15px;
        margin: 0 0 7px 0;
        font-weight: 600;
    }

</style>