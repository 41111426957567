<script>
import { Pie } from 'vue-chartjs'

export default {
  extends: Pie,
    props:['magasins_ventes'],
  mounted() {
    this.renderChart(
      {
        labels: Object.keys(this.magasins_ventes),
        datasets: [
          {
            data: Object.values(this.magasins_ventes),
              backgroundColor: [
                  'rgb(153, 102, 255)',
                  'rgb(255, 159, 64)',
                  "#008d93",
                  "#caf270",
                  "#45c490",
                  '#F00',
                  'rgb(30, 70, 100)',

              ],

              pointBorderColor: '#2554FF',

            hoverBorderColor: '#fff',
          },
        ],
      },
      {
        maintainAspectRatio: true,
        responsive: true,
      }
    )
  },
}
</script>