<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  props:['data1','labels1'],
  mounted() {
    this.renderChart(
      {
        labels:this.labels1,
        datasets: [
          {
            barPercentage: 0.4,
            label: "Ventes",
            backgroundColor: "rgba(52, 195, 143, 0.8)",
            borderColor: "rgba(52, 195, 143, 0.8)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(52, 195, 143, 0.9)",
            hoverBorderColor: "rgba(52, 195, 143, 0.9)",
            data: this.data1
          },
            {
                label: 'Total',
                type:'line',
                fill: false,
                borderColor:'rgb(153, 102, 255)',
                data: this.data1,
            },
        ]
      },
      {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                color: "rgba(166, 176, 207, 0.1)"
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                color: "rgba(166, 176, 207, 0.1)"
              }
            }
          ]
        }
      }
    );
  }
};
</script>